<template>
  <div>
    <div>
      <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="labelCol" :wrapper-col="wrapperCol" >
        <a-form-model-item label="任务名称" prop="taskName">
          {{ form.taskName }}
        </a-form-model-item>
        <a-form-model-item label="指定书籍" prop="booksIds">
          <a-button type="primary" @click="visible = true">{{form.businessNo ? '修改书籍' : '添加书籍'}}</a-button>
          <div v-if="tableList.length > 0">
            <ProductCard :table-list="tableList" />
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>

    <FooterToolBar :collapsed="sideCollapsed">
			<a-button class="margin_right60" @click="() => $router.go(-1)">返回</a-button>
			<a-button type="primary" @click="handleSave">保存</a-button>
    </FooterToolBar>
     <!-- 模态框 -->
    <RadioModal
      v-if="visible"
      :modalVisible="visible"
      :tabType="1"
      :type="0"
      :selectedRowKeys="form.businessNo ? [form.businessNo] : []"
      @cancel="visible = false"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import FooterToolBar from '@/components/FooterToolbar'
import { baseMixin } from '@/store/app-mixin'
import ProductCard from '@/components/product/ProductCard.vue'
import { getShopTaskDetailAPI, editShopTaskAPI } from '@/request/api/marketingCenter'
import RadioModal from '@/components/courseMember/RadioModal'
export default {
  mixins: [baseMixin],
  components: { FooterToolBar, ProductCard, RadioModal },
  data() {
    return {
      labelCol: { span: 2 },
      wrapperCol: { span: 10 },
      form: {
        id: this.$route.params.taskId,
        taskName:'',
        businessNo: ''
      },
      rules: {
        name: [
          { required: true, message: "请输入音频标题", trigger: "blur" },
          { max: 32, message: "最多输入32个字符", trigger: "blur" },
        ],
      },
      visible: false, // 模态框的显示隐藏
      tableList: []
    };
  },
  created() {
    this.getDetail()
  },
  methods: {
    async	getDetail() {
      const params = {
        id: +this.form.id
      }
      const { code, data } = await getShopTaskDetailAPI(params)
      if (code !== 200) return
      Object.assign(this.form, data)
      const courseInfo = {
        businessName: data.businessName,
        businessType: data.businessType,
        coverUrl: data.coverUrl,
        price: data.price
      }
      this.tableList = [courseInfo]
    },
    // 保存的按钮
    handleSave() {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) return
        this.onSave()
      })
    },
    async	onSave() {
      const params = {
      	...this.form
      }
      const { code } = await editShopTaskAPI(params)
      if (code !== 200) return
      this.$message.success('修改成功')
      this.$router.push('/marketingCenter/taskCenter')
    },
    // 弹窗中确认按钮事件
    handleOk(selectedRowKeys, selectedRows) {
      this.visible = false
      if (selectedRowKeys.length === 0) return
      this.form.businessNo = selectedRows.courseNo
      this.tableList = [selectedRows]
    },
  }
};
</script>